import { Controller } from "@hotwired/stimulus";

export default class LoaderComponentController extends Controller {
  static classes = ["hidden"];

  declare readonly hiddenClass: string;

  start() {
    this.element.classList.remove(this.hiddenClass);
  }

  stop() {
    this.element.classList.add(this.hiddenClass);
  }
}
