import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller<HTMLSelectElement> {
  connect() {
    this.element.addEventListener('change', () => this.visit());
  }

  visit() {
    Turbo.visit(this.element.value);
  }
}
