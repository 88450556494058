import { Controller } from "@hotwired/stimulus";

type DirectUploadEvent = {
  detail: {
    file: File;
    id: string;
  }
};

export default class extends Controller {
  static targets = ['uploads'];

  declare readonly uploadsTarget: HTMLDivElement;

  connect() {
    const file = this.element.querySelector("input[type=file]");
    if (!file) return;

    file.addEventListener("direct-upload:initialize", (event: unknown) => {
      this.createUpload(event as DirectUploadEvent);
    });

    file.addEventListener("direct-upload:start", (event: unknown) => {
      this.uploading(event as DirectUploadEvent);
    });

    file.addEventListener("direct-upload:end", (event: unknown) => {
      this.done(event as DirectUploadEvent);
    });
  }

  createUpload(event: DirectUploadEvent) {
    const { id, file } = event.detail;

    const upload = `
      <span id="upload_${id}" class="badge gap-2 items-center my-2">
        ${file.name}
      </span>
    `;

    this.uploadsTarget.insertAdjacentHTML("beforeend", upload);
  }

  uploading(event: DirectUploadEvent) {
    const { id, file } = event.detail;
    const upload = this.uploadsTarget.querySelector(`#upload_${id}`);
    if (!upload) return;

    upload.innerHTML = `
      <span class="loading loading-ring loading-xs"></span>
      ${file.name}
    `;
  }

  done(event: DirectUploadEvent) {
    const { id, file } = event.detail;
    const upload = this.uploadsTarget.querySelector(`#upload_${id}`);
    if (!upload) return;

    upload.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
      </svg>
      ${file.name}
    `;
  }
}
