import { Controller } from "@hotwired/stimulus";

export default class NavController extends Controller {
  static targets = ["toggable"];
  static classes = ["active"];

  declare readonly toggableTarget: HTMLElement;
  declare readonly activeClass: string;

  toggle(e: Event) {
    this.toggableTarget.classList.toggle(this.activeClass)

    e.preventDefault();
  }

  close() {
    this.toggableTarget.classList.remove(this.activeClass);
  }

  closeOnClickOutside(e: Event) {
    if (this.element.contains(e.target as Node)) {
      return;
    }

    this.close();
  }
}
