import { Controller } from "@hotwired/stimulus";

export default class UploadImageController extends Controller {
  static targets = ["chooseButton", "fileInput", "form"];
  static classes = ["loading"];

  declare readonly chooseButtonTarget: HTMLButtonElement
  declare readonly fileInputTarget: HTMLInputElement;
  declare readonly formTarget: HTMLFormElement;

  declare readonly loadingClass: string;

  choose(e: Event) {
    this.fileInputTarget.click();

    e.preventDefault();
  }

  submit() {
    this.chooseButtonTarget.disabled = true;
    this.chooseButtonTarget.classList.add(this.loadingClass)
    this.formTarget.submit();
  }
}
