// Rails functionality
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;

require("@rails/activestorage").start();
import "@rails/actiontext";

// Stimulus controllers
import "./controllers";

// Start Rails UJS
Rails.start();

Turbo.StreamActions.reload = function () {
  Turbo.visit(this.target, { action: "replace" });
};
