import { Controller } from "@hotwired/stimulus";

export default class SearchScrollController extends Controller {
  static targets = ["searchInput", "scrollButton"];
  static classes = ["hidden"];

  declare readonly scrollButtonTarget: HTMLButtonElement;
  declare readonly searchInputTarget: HTMLInputElement;
  declare readonly hiddenClass: string;

  intersepctionObserver: IntersectionObserver;

  connect(): void {
    this.intersepctionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];
      this.toggleScrollButton(entry.isIntersecting);
    });

    this.intersepctionObserver.observe(this.searchInputTarget);
  }

  disconnect(): void {
    this.intersepctionObserver.disconnect();
  }

  toggleScrollButton(hide: boolean) {
    this.scrollButtonTarget.classList.toggle(this.hiddenClass, hide);
  }

  scroll() {
    window.scrollTo(0, 0);

    this.searchInputTarget.focus();
  }
}
