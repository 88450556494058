import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    setTimeout(() => this.call(), 2000);
  }

  call() {
    window.print();
  }
}
